<template>
  <div class="preschool">
    <Navigation :path="path" />
    <img src="~img/preschool/01.png" />
    <div class="one">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>学前阶段课程</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <div class="title">专注 3-6 岁以语言思考力为基础的英才教育课程</div>
      <div class="hidden-xs-only">
        <img src="~img/preschool/02.png" class="pc-biaoge" />
      </div>
      <div class="hidden-sm-and-up">
        <img src="~img/preschool/02.png" class="web-biaoge" />
      </div>
    </div>
    <div class="tow">
      <div class="title-box">
        <img src="~img/preschool/03.png" class="title-icon" />
        <h4>为孩子的好奇心<span>插上翅膀</span></h4>
        <img src="~img/preschool/04.png" class="title-icon" />
      </div>
      <div class="text-box">
        <p>
          爱因斯坦思维科学馆提供像游戏一样快乐的科学·思维体验课程及科学实验活动
        </p>
      </div>
      <img src="~img/preschool/05.png" class="img-list" />
      <p class="explain">
        爱因斯坦思维科学馆是孩子们充满好奇与快乐的地方。科学·思维融合的学习涉及到一些抽象、生涩的概念，因此孩子的第一次体验很重要。通过看、听、触等综合体验与探索活动引发孩子好奇心，同时配备OMO智慧课堂的学习，以语言思考力为基础，通过歌谣与童话故事导入，采用MC多维模型教学的授课方式，为孩子好奇心插上想象的翅膀，从而培养孩子的专注力、自主学习能力、科学思维逻辑能力和科学探索精神。
      </p>
    </div>
    <div class="pc-three hidden-xs-only">
      <div class="left">
        <img src="~img/preschool/06.png" class="logo" />
        <div class="name">
          <img src="~img/preschool/07.png" class="icon-img" />
          <p class="p1">老师说</p>
        </div>
        <p class="p2">孩子的力量是由幼儿期的经验决定的。</p>
        <p class="p2">
          但是与单纯的经验不同，带着“为什么？”的好奇心扩张思考的经验才是真的。
        </p>
      </div>
      <img src="~img/preschool/08.png" class="video" />
    </div>
    <div class="web-three hidden-sm-and-up">
      <img src="~img/preschool/06.png" class="logo" />
      <p class="p1">老师说</p>
      <p class="p2">孩子的力量是由幼儿期的经验决定的。</p>
      <p class="p2">
        但是与单纯的经验不同，带着“为什么？”的好奇心扩张思考的经验才是真的。
      </p>
      <img src="~img/preschool/08.png" class="video" />
    </div>
    <div class="four">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>能学习到什么呢？</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <img src="~img/preschool/09.png" />
          <p class="p1">思考能力</p>
          <p class="p2">理解能力+推论能力+应用能力</p>
        </li>
        <li>
          <img src="~img/preschool/10.png" />
          <p class="p1">学习态度</p>
          <p class="p2">分享+领导力+专注力+自主学习能力+科学日记</p>
        </li>
        <li>
          <img src="~img/preschool/11.png" />
          <p class="p1">沟通能力</p>
          <p class="p2">好奇心+动机+自信+毅力</p>
        </li>
      </ul>
    </div>
    <div class="five">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>咨询流程</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <img src="~img/preschool/13.png" />
          <p class="p1">Step 1</p>
          <p class="p2">多维能力测评</p>
          <p class="p3">
            通过爱因斯坦思维科学馆专业多元测试问卷，发现孩子各项能力发展情况和性格中还未被发掘的闪光点
          </p>
        </li>
        <li>
          <img src="~img/preschool/14.png" />
          <p class="p1">Step 2</p>
          <p class="p2">1V1专业的咨询和解答</p>
          <p class="p3">
            针对测试结果和课程体系,专业顾问老师为您进行1V1专业的咨询和解答
          </p>
        </li>
        <li>
          <img src="~img/preschool/15.png" />
          <p class="p1">Step 3</p>
          <p class="p2">公开课</p>
          <p class="p3">
            和小朋友一起参加一场培养好奇心和创造力的科学·思维活动，发现孩子闪光的一面
          </p>
        </li>
      </ul>
    </div>
    <div class="six">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>快来体验吧！</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <p class="p1">公开课预约</p>
          <p class="p2">
            公开课是体验爱因斯坦思维科学馆最特别最直接的方式。和小朋友一起参加一场培养好奇心和创造力的思维科学探索活动，发现孩子闪光的一面。
          </p>
          <div>More View</div>
        </li>
        <li>
          <p class="p1">少儿强基计划 <br />科学思维及素养能力测评</p>
          <p class="p2">
            少儿强基计划科学思维及素养能力测评是爱因斯坦思维科学馆为了诊断孩子的认知性，创意性，情感态度而开发的专业多维能力检测系统。
          </p>
          <div>More View</div>
        </li>
      </ul>
    </div>
    <consulting />
    <foot />
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import Navigation from "@/components/header.vue";
import footer from "@/components/footer.vue";
import consulting from "@/components/consulting.vue";

export default {
  components: {
    Navigation,
    foot: footer,
    consulting,
  },
  data() {
    return {
      path: "/preschool",
    };
  },
};
</script>
<style lang="less" scoped>
.preschool {
  .title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .title-icon {
      width: 0.8rem;
      height: 0.38rem;
    }
    h4 {
      font-size: 0.45rem;
      line-height: 0.8rem;
      margin-left: 0.47rem;
      margin-right: 0.47rem;
    }
  }
  .one {
    padding-top: 1.07rem;
    .title {
      max-width: 6.42rem;
      margin: auto;
      margin-top: 0.41rem;
      background: #f3ce4c;
      border-radius: 0.51rem;
      text-align: center;
      line-height: 0.51rem;
      color: #3b73ce;
      font-size: 0.27rem;
    }
    .pc-biaoge {
      width: 13.3rem;
      margin: auto;
      margin-top: 0.5rem;
    }
    .web-biaoge {
      width: 95%;
      margin: auto;
      margin-top: 0.5rem;
    }
  }
  .tow {
    padding-top: 1rem /* 100/100 */;
    .title-box {
      .title-icon {
        width: 1.13rem /* 113/100 */;
        height: 1.04rem /* 104/100 */;
      }
      h4 {
        font-weight: 500;
      }
      span {
        color: #4f82e1;
      }
    }
    .text-box {
      max-width: 8.04rem /* 804/100 */;
      border-radius: 0.38rem /* 38/100 */;
      line-height: 0.38rem /* 38/100 */;
      background: #4f82e1;
      font-size: 0.22rem /* 22/100 */;
      color: #fff;
      margin: auto;
      text-align: center;
      margin-top: 0.16rem /* 16/100 */;
    }
    .img-list {
      max-width: 14.85rem /* 1485/100 */;
      margin: auto;
      margin-top: 0.62rem /* 62/100 */;
    }
    .explain {
      font-size: 0.22rem /* 22/100 */;
      color: #3e3a3a;
      max-width: 14.01rem /* 1401/100 */;
      margin-left: 2.2rem /* 254/100 */;
      margin-top: 0.57rem /* 57/100 */;
      line-height: 0.43rem /* 43/100 */;
    }
    @media (max-width: 750px) {
      h4 {
        text-align: center;
      }
      .text-box {
        padding: 10px;
      }
      .explain {
        margin: 10px;
      }
    }
  }
  .pc-three {
    padding-top: 0.95rem /* 95/100 */;
    padding-bottom: 1.07rem /* 107/100 */;
    padding-left: 2.74rem /* 274/100 */;
    background: #4f82e1;
    margin-top: 1.46rem /* 146/100 */;
    position: relative;
    .left {
      color: #fff;
      .logo {
        width: 2.82rem /* 282/100 */;
        margin-bottom: 0.67rem /* 67/100 */;
      }
      .name {
        position: relative;
        .p1 {
          font-size: 0.65rem;
          margin-bottom: 0.2rem /* 20/100 */;
          position: relative;
        }
        img {
          position: absolute;
          width: 2.67rem /* 267/100 */;
          bottom: -0.15rem;
          left: -0.4rem /* 20/100 */;
        }
      }
      .p2 {
        font-size: 0.2rem /* 20/100 */;
        padding-top: 0.2rem /* 35/100 */;
      }
    }
    .video {
      position: absolute;
      width: 5.43rem /* 543/100 */;
      top: 0.98rem /* 98/100 */;
      right: 2.54rem /* 254/100 */;
    }
  }
  .web-three {
    margin-top: 40px;
    background: #4f82e1;
    padding: 10px;
    .logo {
      width: 80%;
    }
    .p1 {
      font-size: 22px;
      color: #fff;
      line-height: 50px;
    }
    .p2 {
      color: #fff;
      line-height: 22px;
    }
    .video {
      margin-top: 20px;
    }
  }
  .four {
    padding-top: 1.25rem /* 125/100 */;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 2.82rem /* 282/100 */;
      padding-right: 2.5rem /* 250/100 */;
      margin-top: 1.17rem /* 117/100 */;
      li {
        width: 4.1rem /* 410/100 */;
      }
      .p1 {
        font-size: 0.25rem /* 25/100 */;
        line-height: 0.6rem /* 50/100 */;
      }
      .p2 {
        background: url("~img/preschool/12.png") no-repeat;
        background-size: 80% 100%;
        font-size: 0.18rem /* 20/100 */;
        line-height: 0.4rem /* 40/100 */;
        padding-left: 0.1rem /* 10/100 */;
      }
    }
    @media (max-width: 750px) {
      ul {
        padding: 0;
        margin-top: 20px;
        li {
          width: 80%;
          margin-bottom: 20px;
          margin: auto;
        }
      }
    }
  }
  .five {
    padding-top: 1.82rem /* 182/100 */;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 2.67rem /* 267/100 */;
      padding-right: 2.75rem /* 275/100 */;
      margin-top: 1.06rem /* 117/100 */;
      li {
        width: 3.51rem /* 351/100 */;
        img {
          width: 1.94rem /* 194/100 */;
          margin: auto;
        }
        .p1 {
          font-size: 0.2rem /* 20/100 */;
          color: #727171;
          text-align: center;
          line-height: 0.6rem /* 60/100 */;
          margin-top: 0.4rem /* 20/100 */;
        }
        .p2 {
          font-size: 0.35rem /* 35/100 */;
          text-align: center;
          color: #4f82e1;
        }
        .p3 {
          font-size: 0.22rem /* 22/100 */;
          color: #595757;
          line-height: 0.4rem /* 40/100 */;
          margin-top: 0.3rem /* 30/100 */;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding: 0;
        margin-top: 20px;
        li {
          width: 80%;
          margin-bottom: 20px;
          margin: auto;
        }
      }
    }
  }
  .six {
    padding-top: 1.6rem /* 160/100 */;
    padding-bottom: 1.14rem /* 114/100 */;
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-right: 2.24rem /* 224/100 */;
      padding-left: 2.35rem /* 235/100 */;
      margin-top: 1.03rem /* 103/100 */;
      li {
        width: 6.67rem /* 667/100 */;
        height: 4.03rem /* 403/100 */;
        background: url("~img/preschool/16.png") no-repeat;
        background-size: 100% 100%;
        padding-top: 0.61rem /* 61/100 */;
        text-align: center;
        position: relative;
        .p1 {
          font-size: 0.35rem /* 35/100 */;
          color: #4f82e1;
        }
        .p2 {
          margin: auto;
          margin-top: 0.32rem /* 62/100 */;
          max-width: 4.38rem /* 438/100 */;
          font-size: 0.2rem /* 20/100 */;
          text-align: left;
          line-height: 0.39rem /* 39/100 */;
        }
        div {
          margin: auto;
          width: 1.67rem /* 167/100 */;
          height: 0.42rem /* 42/100 */;
          border-radius: 0.42rem /* 42/100 */;
          background: rgb(137, 162, 245);
          font-size: 0.2rem /* 20/100 */;
          line-height: 0.42rem /* 42/100 */;
          color: #fff;
          position: absolute;
          bottom: 0.32rem /* 32/100 */;
          left: 50%;
          transform: translate(-50%);
        }
      }
      li:nth-child(2) {
        padding-top: 0.25rem;
        .p2 {
          margin-top: 0.15rem;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding: 0;
        margin-top: 20px;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>